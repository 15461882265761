import { Menu } from "antd";
import { useState, useEffect } from "react";
import { ReactComponent as WhiskrLogo } from "../../assets/whiskr-logo.svg";

export const Navbar = () => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [textColor, setTextColor] = useState("white");
  const [logoFill, setLogoFill] = useState("white");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      setBackgroundColor(isScrolled ? "white" : "transparent");
      setTextColor(isScrolled ? "#2A3240" : "white");
      setLogoFill(isScrolled ? "#2A3240" : "white");
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  const scrollToEarlyAccess = () => {
    const getAccessSection = document.getElementById("early-access");
    if (getAccessSection) {
      getAccessSection.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const emailInput = document.querySelector<HTMLInputElement>('#early-access input[type="email"]');
        if (emailInput) {
          emailInput.focus();
        }
      }, 800);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: backgroundColor,
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        transition: "background-color 0.5s ease",
        zIndex: 1000,
      }}
    >
      <Menu
        mode="horizontal"
        className={scrolled ? "scrolled" : ""}
        selectable={false}
        disabledOverflow={true}
        items={[
          {
            key: "logo",
            label: (
              <div
                className="logo-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flex: 1,
                  cursor: "pointer",
                  height: "46px",
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <WhiskrLogo
                  fill={logoFill}
                  style={{ width: "50px", height: "auto", paddingTop: "4px" }}
                />
              </div>
            ),
          },
          {
            key: "",
            label: "",
            onMouseEnter: ({ domEvent }) => {
              (domEvent.target as HTMLElement).style.cursor = "auto";
            },
          },
          {
            key: "get-access",
            label: "Get Access",
            style: { color: textColor },
            onClick: scrollToEarlyAccess,
          },
          {
            key: "contact",
            label: "Contact",
            style: { color: textColor },
            className: "contact-link",
            onClick: () => {
              const contactSection = document.getElementById("contact-section");
              if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" });
              }
            },
          },
        ]}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          maxWidth: "1160px",
          width: "100%",
          margin: "0 auto",
          backgroundColor: "transparent",
          color: textColor,
          transition: "background-color 0.5s ease, color 0.5s ease",
          flexWrap: "nowrap",
          overflow: "visible"
        }}
      />
    </div>
  );
};
