import { Button, Collapse, Divider, Input, InputRef } from "antd";
import { Navbar } from "./components/navbar";
import { Footer } from "antd/es/layout/layout";
import { ReactComponent as WhiskrLogo } from "./assets/whiskr-logo.svg";
import "./app.css";
import { useRef, useState } from "react";
import axios from "axios";

const imageStyle = { maxWidth: "650px", width: "100%" };

const App = () => {
  const emailInputRef = useRef<InputRef>(null);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
      return;
    }

    setError("");
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/early-access/add-email`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_EARLY_ACCESS_TOKEN}`,
          },
        }
      );

      setEmail("");
      setIsSubmitted(true);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong, please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="hero-section">
        <div className="container">
          <div className="hero-content">
            <h1 className="hero-title">
              <span>Whiskr</span> <br /> All your pet needs in one place
            </h1>
            <h4 className="hero-subtitle">
              Bringing Pet Owners and Service Providers Together
            </h4>
            <div className="button-container">
              <Button
                type="primary"
                className="primary-button"
                onClick={() => {
                  const element = document?.getElementById("early-access");
                  if (element) {
                    window.scrollTo({
                      top: element.offsetTop,
                      behavior: "smooth",
                    });
                    setTimeout(() => {
                      emailInputRef.current?.focus();
                    }, 800);
                  }
                }}
              >
                Get Early Access
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="half-width">
          <h1>About Whiskr</h1>
          <h4 className="text-h4">
            Whiskr is transforming the way pet owners care for their pets,
            offering a comprehensive platform designed to simplify pet
            management. From health tracking to connecting with trusted service
            providers, Whiskr provides an all-in-one solution to ensure your
            pets live their healthiest, happiest lives.
          </h4>
        </div>

        <div>
          <img
            src="https://ik.imagekit.io/petpals/landing-page/about.png"
            alt="dog"
            style={{ ...imageStyle, maxWidth: "550px" }}
          />
        </div>
      </div>

      <h1 className="features-title">Features</h1>

      <div className="features-section">
        <div className="feature-row">
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/nutrition.png"
              alt="dog"
              style={imageStyle}
            />
          </div>
          <div className="half-width">
            <h1 className="text-right">Nutrition Tracking</h1>
            <h4 className="text-right text-h4">
              Our Nutrition Tracking feature offers a comprehensive solution for
              pet owners to monitor, manage, and optimize their pets' diets.
              From tracking daily food intake to managing recipes and scheduling
              meal reminders, this feature supports you in maintaining a
              balanced and healthy diet for your pet.
            </h4>
          </div>
        </div>
        <Collapse
          size="large"
          className="dropdown-collapse"
          items={[
            {
              key: "1",
              label: "Read More about Nutrition Tracking",
              children: (
                <div>
                  <ul>
                    <li>
                      <strong>Food Journal Statistics:</strong> Easily visualize
                      your pet's dietary intake with interactive charts and
                      summaries, showing the types and quantities of food
                      consumed. This feature enables you to analyze dietary
                      patterns, helping ensure your pet's nutrition aligns with
                      health recommendations.
                    </li>
                    <li>
                      <strong>Recipe Management:</strong> Create and organize
                      custom recipes tailored to your pet's dietary needs. Each
                      recipe includes ingredients, preparation instructions, and
                      even image uploads, making it easy to plan meals that
                      offer variety and balanced nutrition.
                    </li>
                    <li>
                      <strong>Meal Reminders:</strong> Stay on top of feeding
                      schedules with customizable reminders, ensuring your pet
                      is fed on time every day. Easily manage and adjust feeding
                      times to match your pet's routine, keeping them on a
                      consistent, healthy schedule.
                    </li>
                  </ul>

                  <p>
                    With Nutrition Tracking, you'll have all the tools you need
                    to provide your pet with balanced meals, structured
                    schedules, and the best possible nutrition, all in one
                    intuitive interface.
                  </p>
                </div>
              ),
            },
          ]}
          expandIconPosition="end"
        />
      </div>

      <div className="features-section">
        <div className="feature-row">
          <div className="half-width">
            <h1>Health Management</h1>
            <h4 className="text-h4">
              Our Health Management feature empowers pet owners to take charge
              of their pets' well-being with an organized approach to healthcare
              and wellness tracking. From vet visits to vaccinations and health
              milestones, this feature keeps you informed and on track with your
              pet's health needs.
            </h4>
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/health.png"
              alt="dog"
              style={imageStyle}
            />
          </div>
        </div>

        <Collapse
          size="large"
          className="dropdown-collapse"
          items={[
            {
              key: "1",
              label: "Read More about Health Management",
              children: (
                <div>
                  <ul>
                    <li>
                      <strong>Health Records:</strong> Keep an easily accessible
                      record of all health-related information, including
                      vaccinations, medications, and vet visits, helping you
                      stay informed about your pet's medical history.
                    </li>
                    <li>
                      <strong>Check-Up Reminders:</strong> Schedule reminders
                      for regular vet visits and health check-ups to ensure your
                      pet remains in optimal health. Receive alerts for upcoming
                      appointments so you never miss a critical health update.
                    </li>
                    <li>
                      <strong>Wellness Tracking:</strong> Monitor ongoing health
                      metrics and trends to identify changes in your pet's
                      condition early, empowering you to take proactive steps in
                      their care.
                    </li>
                  </ul>

                  <p>
                    With Health Management, you'll have peace of mind knowing
                    that every aspect of your pet's health is recorded,
                    scheduled, and easily accessible.
                  </p>
                </div>
              ),
            },
          ]}
          expandIconPosition="end"
        />
      </div>

      <div className="features-section">
        <div className="feature-row">
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/activity.png"
              alt="dog"
              style={imageStyle}
            />
          </div>
          <div className="half-width">
            <h1 className="text-right">Activity Tracking</h1>
            <h4 className="text-right text-h4">
              Our Activity Tracking feature helps pet owners ensure their pets
              lead an active, healthy lifestyle. Track exercise patterns, set
              activity goals, and monitor progress to keep your pet fit,
              engaged, and happy.
            </h4>
          </div>
        </div>

        <Collapse
          size="large"
          className="dropdown-collapse"
          items={[
            {
              key: "1",
              label: "Read More about Activity Tracking",
              children: (
                <div>
                  <ul>
                    <li>
                      <strong>Activity Monitoring:</strong> Track daily exercise
                      routines, playtime, and walk schedules, so you always have
                      a clear picture of your pet's activity levels.
                    </li>
                    <li>
                      <strong>Goal Setting:</strong> Set customizable activity
                      goals based on your pet's age, breed, and energy level,
                      and receive updates on their progress, helping you keep
                      your pet active and engaged.
                    </li>
                    <li>
                      <strong>Progress Insights:</strong> Review your pet's
                      activity history with visually engaging charts and
                      summaries, allowing you to identify trends, make
                      adjustments, and ensure they get the right amount of
                      exercise for their health and happiness.
                    </li>
                  </ul>

                  <p>
                    Activity Tracking provides you with everything needed to
                    keep your pet physically active, ensuring they remain
                    healthy, fit, and full of energy.
                  </p>
                </div>
              ),
            },
          ]}
          expandIconPosition="end"
        />
      </div>

      <div className="features-section">
        <div className="feature-row">
          <div className="half-width">
            <h1>Pet Services Directory</h1>
            <h4 className="text-h4">
              Our Pet Services Directory connects pet owners with trusted,
              high-quality providers in their area, making it easier to find
              everything your pet needs in one place. From grooming to pet
              boarding, you'll have access to a network of reputable
              professionals who truly care for pets.
            </h4>
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/service.png"
              alt="dog"
              style={imageStyle}
            />
          </div>
        </div>

        <Collapse
          size="large"
          className="dropdown-collapse"
          items={[
            {
              key: "1",
              label: "Read More about Pet Services Directory",
              children: (
                <div>
                  <ul>
                    <li>
                      <strong>Comprehensive Listings:</strong> Discover a
                      variety of pet services, including grooming, training,
                      boarding, and veterinary care, with each service vetted
                      and rated by the community for reliability and quality.
                    </li>
                    <li>
                      <strong>Detailed Service Profiles:</strong> View each
                      provider's detailed profile, including services offered,
                      pricing, user reviews, and location information, allowing
                      you to make an informed decision about your pet's care.
                    </li>
                    <li>
                      <strong>Service Ratings and Reviews:</strong> Access
                      ratings and reviews from other pet owners, giving you
                      confidence that your chosen provider has a proven record
                      of excellence.
                    </li>
                  </ul>

                  <p>
                    With the Pet Services Directory, pet owners can effortlessly
                    find and connect with service providers who are dedicated to
                    their pets' well-being, all in one convenient location.
                  </p>
                </div>
              ),
            },
          ]}
          expandIconPosition="end"
        />
      </div>

      <div className="features-section">
        <div className="feature-row">
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/admin-mockup.png"
              alt="dog"
              width={617}
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="half-width">
            <h1 className="text-right">Admin Panel</h1>
            <h4 className="text-right text-h4">
              The Admin Panel is a powerful, user-friendly interface designed to
              help pet service providers manage their accounts, services, and
              schedules with ease. From creating an account to organizing
              bookings, the Admin Panel centralizes everything needed to
              efficiently run and grow a pet care business.
            </h4>
          </div>
        </div>

        <Collapse
          size="large"
          className="dropdown-collapse"
          items={[
            {
              key: "1",
              label: "Read More about Admin Panel",
              children: (
                <div>
                  <ul>
                    <li>
                      <strong>Account Creation and Management:</strong> Easily
                      create, update, and manage your account details, ensuring
                      accurate and up-to-date information for pet owners seeking
                      your services.
                    </li>
                    <li>
                      <strong>Guided Onboarding:</strong> Step-by-step
                      onboarding to help you set up your account quickly and get
                      acquainted with all the features that make managing your
                      business a breeze.
                    </li>
                    <li>
                      <strong>Integrated Calendar:</strong> Keep track of
                      appointments and availability with a built-in calendar,
                      simplifying the scheduling process and ensuring a
                      well-organized booking system.
                    </li>
                    <li>
                      <strong>Service Management:</strong> List and customize
                      the pet services you offer, from grooming to extended pet
                      care, allowing pet owners to clearly see and book what
                      they need.
                    </li>
                    <li>
                      <strong>Edit Account Settings:</strong> Update account
                      details, adjust service offerings, and manage your profile
                      with full control over your account settings.
                    </li>
                  </ul>

                  <p>
                    The Admin Panel equips pet service providers with essential
                    tools to streamline their business operations and deliver
                    top-quality services, helping them to grow and better
                    connect with the pet-loving community.
                  </p>
                </div>
              ),
            },
          ]}
          expandIconPosition="end"
        />
      </div>

      <div className="dark-section">
        <div className="container">
          <div className="dark-content">
            <h1>Coming Soon to Android and iOS</h1>
            <h4>
              We are excited to announce that Whiskr will soon be available on
              both Android and iOS. Stay tuned for updates!
            </h4>
            <div className="app-badges">
              <img
                src="/playstore.png"
                alt="Android"
                style={{ width: "50px" }}
              />
              <img src="/appstore.png" alt="iOS" style={{ width: "50px" }} />
            </div>
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/petpals/landing-page/android-ios-mockup.png"
              alt="dog"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>

      <div id="early-access" className="early-access-section">
        <div className="container">
          <div className="early-access-content">
            <h1 className="early-access-title">Get Early Access to Whiskr</h1>
            <h4 className="text-h4">
              Be among the first to experience Whiskr! Sign up below to get
              early access to our platform and stay updated on our progress.
            </h4>
          </div>

          <div className="email-signup">
            <div className="email-input-container">
              <Input
                ref={emailInputRef}
                placeholder="Enter your email"
                style={{
                  width: "300px",
                  fontSize: "16px",
                }}
                type="email"
                required
                value={email}
                onChange={handleEmailChange}
                disabled={loading || isSubmitted}
              />
              <span className="error-message">{error}</span>
            </div>

            <Button
              type="primary"
              style={{
                backgroundColor: isSubmitted ? "#0fa327" : "#2A3240",
                borderColor: isSubmitted ? "#0fa327" : "#2A3240",
                color: isSubmitted ? "white" : "white",
                marginTop: "2px",
                transition: "all 0.3s ease",
              }}
              onClick={handleSubmit}
              disabled={!!error || loading || isSubmitted}
              className="early-access-button"
            >
              {isSubmitted ? "You're Signed Up! ✓" : "Sign Up For Early Access"}
            </Button>
          </div>
        </div>
      </div>

      <Divider
        style={{
          width: "80%",
          minWidth: "80%",
          margin: "24px auto",
          borderColor: "rgba(42, 50, 64, 0.25)",
        }}
      />

      <div className="contact-section" id="contact-section">
        <div className="contact-content">
          <h1>Contact Us</h1>
          <h4 className="text-h4">
            We'd love to hear from you! Whether you have questions, feedback, or
            just want to say hello, our team is here to help. Reach out to us
            through our email address:{" "}
            <a href="mailto:whiskrpet@gmail.com" className="contact-email">
              whiskrpet@gmail.com
            </a>
            .
          </h4>
        </div>
      </div>

      <Footer className="footer">
        <div>
          <WhiskrLogo style={{ width: "50px", height: "auto" }} fill="white" />
        </div>
      </Footer>
    </div>
  );
};

export default App;
